export const AUTH = 'AUTH';
export const CREATE = 'CREATE';
export const LOGOUT = 'LOGOUT';
export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_WEIGH = 'FETCH_WEIGH';
export const FETCH_VEHICLE = 'FETCH_VEHICLE';
export const FETCH_CALIBRATION = 'FETCH_CALIBRATION';
export const FETCH_PORT = 'FETCH_PORT';
export const FETCH_ALL = 'FETCH_ALL';
export const GET_DATA_SUCCESS ='GET_DATA_SUCCESS';
export const GET_DATAKALIBRASI_SUCCESS ='GET_DATAKALIBRASI_SUCCESS';
export const GET_DATAINFORM_SUCCESS = ' GET_DATAINFORM_SUCCESS';
export const GET_DATAWEIGH_SUCCESS = 'GET_DATAWEIGH_SUCCESS';
export const GET_DATAPORT_SUCCESS = 'GET_DATAPORT_SUCCESS';
export const GET_CHECKPORT_SUCCESS = 'GET_CHECKPORT_SUCCESS';
export const GET_ALLDATA_SUCCESS = 'GET_ALLDATA_SUCCESS';
export const CONNECT_PORT_SUCCESS = 'CONNECT_PORT_SUCCESS';
export const ADD_MANUAL_SUCCESS = 'ADD_MANUAL_SUCCESS';
export const GET_VEHICLE_REPORT = 'GET_VEHICLE_REPORT';
export const GET_DATA_KENDARAAN_BY_DATE = 'GET_DATA_KENDARAAN_BY_DATE';
export const GET_DATA_KALIBRASI_BY_DATE = 'GET_DATA_KALIBRASI_BY_DATE';
export const GET_DATA_TRIGGER_SUCCESS = 'GET_DATA_TRIGGER_SUCCESS';
