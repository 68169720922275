import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  paper: {
    // width:'567px',
    // height:'520px',
    // left:'537px',
    boxShadow:'0px 0px 3px rgba(0, 0, 0, 0.14)',
    alignContent: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    marginTop: '10%',
  },
  text: {
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color:'#FFFFFF',
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: '#37474F',
    borderRadius: '7px 7px 0px 0px',
    // height: '36px',
    // padding: '3%',
  },
  dataFormContainer: {
    // position:'fixed',
    // width: '100%',
    // height: '100%',
    // backgroundColor:'black',
    // backgroundColor:'rgba(0,0,0,.75)',
    // alignItems: 'center',
    // justifyContent:'center',
    // top: 0,
    // left: 0,
    // zIndex: 1,
    // backdropFilter: 'blur(21px)',

  //   position:'fixed',
  // background:'white',
  // width:'100%',
  // height:'auto',
  // top:'50%',
  // left:'50%',
  // transform: 'translate(-50%,-50%)',

  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.6)',
  },
  textBtn: {
    height: '32px',
    left:'6px',
    right: '8px',
    top: 'calc(50% - 32px/2)',
    fontFamily: 'Roboto',
    fontWeight:'500',
    fontSize:'14px',
    lineHeight:'16px',
    textAlign:'center',
    letterSpacing:'1.25px',
    textTransform: 'uppercase',
  },
  btnInput: {
    justifyContent:'center',
    alignItems:'center',
    width:'199px',
    height:'66px',
    top:'-70px',
    borderRadius:'4px'
  },
}))
