import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from './Component/LoginForm/LoginForm';
import Dashboard from './Component/Dashboard/Dashboard';
import Informasi from './Component/DashboardHome/Inform';
import CarInfo from './Component/DashboardHome/CarInfo';
import Home from './Component/DashboardHome/Home';
import DataForm from './Component/DataForm/DataForm';
import DataKendaraan from './Component/DataKendaraan/Dashboard';
import DataKalibrasi from './Component/DataKalibrasi/Dashboard';

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />}/>
        <Route path='dashboard' element={<Dashboard />}>
          <Route path='home' element={<Home />}/>
          <Route path='carList' element={<DataKendaraan />}/>
          <Route path='dataList' element={<DataKalibrasi />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
