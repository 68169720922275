import React, {useState, useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import makeStyles from './styles';
import { getDataKendaraan, getReportDataKendaraanByDate, getDataKendaraanByDate, getReportDataKendaraan } from '../../actions/dataKendaraan';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from "material-ui-search-bar";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'no',
    numeric: false,
    disablePadding: true,
    label: 'NO',
  },
  {
    id: 'created',
    numeric: true,
    disablePadding: false,
    label: 'TIMESTAMP',
  },
  {
    id: 'vin',
    numeric: true,
    disablePadding: false,
    label: 'NOMOR LAMBUNG',
  },
  {
    id: 'jenis_muatan',
    numeric: true,
    disablePadding: false,
    label: 'JENIS MUATAN',
  },
  {
    id: 'asal_muatan',
    numeric: true,
    disablePadding: false,
    label: 'ASAL MUATAN',
  },
  {
    id: 'berat_muatan',
    numeric: true,
    disablePadding: false,
    label: 'BERAT MUATAN',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'JENIS',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
    const classes = makeStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableText}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = makeStyles();

  const { numSelected } = props;

  return (
    <Box
      sx={{
        backgroundColor:'#37474F',
        color:'#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderRadius: '5px 5px 0px 0px',
        padding:'3px',
				height:'36px'
      }}
    >
        <Typography
          sx={{ ml: 2 }}
          style={{ fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: '18px' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          TABEL DATA TIMBANG
        </Typography>
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {

  const dispatch = useDispatch();
  const location = useLocation();
  const classes = makeStyles();

  const [order, setOrder] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('created');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [dateStart, setDateStart] = useState(null);
  const [minDateStart, setMinDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [searched, setSearched] = useState("");
  const dataKendaraan = useSelector((state) => state.dataKendaraan);
  const [downloaded, setDownloaded] = useState(false);
  const [addDays, setAddDays] = useState(false);
  const timer = useRef();
  const [downloadProgress, setDownloadProgress] = useState(false);
  const [rows, setRows] = useState(dataKendaraan);
  const startDate = new Date();
  const [auth, setAuth] = useState(decodeURI(cookies.get('access_token')));
  const [userId, setUserId] = useState(decodeURI(cookies.get('user_id')));

  const getDatabyDate = useCallback(() => {
    if(dateStart !== null && dateEnd !== null ) {
      dispatch(getDataKendaraanByDate(auth, dateStart, dateEnd));
    } else {
      dispatch(getDataKendaraan(auth));
    }
  }, [dateStart, dateEnd, dispatch]);

  useEffect(() => {
    getDatabyDate(auth)
    console.log(minDateStart);
  }, [getDatabyDate]);

  timer.current = window.setTimeout(() => {
	        setLoading(false);
	    }, 1500);

  const handlePrint = (token, user_id, start, end) => {
    if(start !== null && end !== null)
    {
      dispatch(getReportDataKendaraanByDate(token, user_id, start, end));
    } else {
      dispatch(getReportDataKendaraan(token, user_id))
    }

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataKendaraan.vehicles.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    console.log(rowsPerPage);
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataKendaraan.vehicles.length) : 0;

  return (
    <Grid container spacing={3}>
    <Grid item xs={12} sx={{ display:'flex', flexDirection:'row' }}>
        {/* Search */}
        <Grid item xs={12} lg={5}>
          <Box
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              }}
            noValidate
            autoComplete="off"
          >
          <TextField
            style={{ width:'100%', backgroundColor: '#FFFFFF'}}
            value={searched}
            onChange={(event) => {setSearched(event.target.value);}}
            variant="outlined"
          />
          <SearchIcon style={{color: 'rgba(0, 0, 0, 0.54)', zIndex:'0'}} sx={{ ml:-6, width: '46px'}}/>
          </Box>
        </Grid>
        {/*Date*/}
        <Grid item xs={6} lg={4} sx={{ display:'flex', ml:'1%', flexDirection:'row'}}>
        {/* Start Date */}
        <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="yyyy-MM-dd"
              value={dateStart}
              disableFuture
              onChange={(newValue) => {
                    setDateStart(moment(newValue).format('yyyy-MM-DD'));
                    setMinDateStart(newValue);
                    }}
              renderInput={(params) => <TextField {...params} style={{backgroundColor: '#FFFFFF'}}/>}
              />
            </LocalizationProvider>
        </Grid>
            <Typography className={classes.text} style={{fontWeight:'bold'}} sx={{ml:2, mr: 2, mt: 2}}> TO </Typography>
          {/* End Date */}
          <Grid item xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                inputFormat="yyyy-MM-dd"
                value={dateEnd}
                disableFuture
                minDate={minDateStart}
                onChange={(newValue) => {
                      setDateEnd(moment(newValue).format('yyyy-MM-DD'));
                      }}
                renderInput={(params) => <TextField {...params} style={{backgroundColor: '#FFFFFF'}}/>}
                />
              </LocalizationProvider>
          </Grid>
        </Grid>
      <Grid item xs={12} md={2.6}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color='success'
          onClick={() => handlePrint(auth, userId, dateStart, dateEnd)}
          style={{
            backgroundColor: '#0C8D19[500]',
            justifyContent:'center',
            alignItems:'center',
            padding:'6px 6px 6px 6px',
            height:'45px',
            borderRadius:'4px',
            marginLeft:'7%',
            marginTop:'2%',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#0C8D19[700]',
            }
          }}
        >
        <PictureAsPdfIcon style={{padding:2}}/>
        PRINT TO PDF
        </Button>
      </Grid>
      </Grid>
      {/*Table*/}
      <Grid item xs={12}>
      <Box sx={{ width:'100%' }}>
        <Paper sx={{ maxWidth: '100vw', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size='small'
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={dataKendaraan.vehicles.length}
              />
              {
                !dataKendaraan.vehicles.length?
                <div>
                { loading ?
                  <div>
                      <CircularProgress color="inherit" size={30} />
                  </div>
                  :
                  <Typography sx={{ml:2}} style={{fontFamily: 'Noto Sans', fontSize:'14px'}} > Data tidak ditemukan </Typography>
                }
                </div>
                :
                <TableBody>
                {stableSort(dataKendaraan.vehicles, getComparator(order, orderBy))
                  .filter((vehicle) => {
                    if (searched == "") {
                      return vehicle
                    } else if (
                      vehicle.vin.toLowerCase().includes(searched.toLowerCase()) ||
                      vehicle.jenis_muatan.toLowerCase().includes(searched.toLowerCase()) ||
                      vehicle.asal_muatan.toLowerCase().includes(searched.toLowerCase())
                    )
                    {
                      return vehicle
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((vehicle, index) => (
                        <TableRow className={classes.textRow}>
                          <TableCell
                            component="th"
                          >
                            {(page*rowsPerPage)+index + 1}
                          </TableCell>
                          <TableCell>{vehicle.created}</TableCell>
                          <TableCell>{vehicle.vin}</TableCell>
                          <TableCell>{vehicle.jenis_muatan}</TableCell>
                          <TableCell>{vehicle.asal_muatan}</TableCell>
                          <TableCell>{vehicle.berat_muatan}</TableCell>
                          <TableCell>{vehicle.status}</TableCell>
                        </TableRow>
                ))}
                </TableBody>
              }
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 25, 35]}
            component="div"
            count={dataKendaraan.vehicles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
        </Grid>
    </Grid>

  );
}
