import { makeStyles } from '@mui/styles';
import Image from '../../Image/Login.png';

export default makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: 'no-repeat',
  },
  paper: {
    width: '21vw',
    border: '1px solid #C4C4C4',
    padding: '25px',
    alignItems: 'center',
    marginTop:'-30%',
    marginLeft:'-10%'
    },
  paper2: {
    alignItems: 'center',
    padding: '25px',
    width: '21.05vw',
    height: '20px',
  },
  login: {
    letterSpacing: '0.15px',
    color: '#024F17',
    width: '74px',
  },
  text: {
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.15px',
  },
  header: {
    width: '35vw',
    height: '49px',
    lineHeight: '65px',
    letterSpacing: '0.15px',
    color: '#FECC00'
  },
  span0: {
    color: '#FF8C00',
  },
  span1: {
    fontSize: '24px',
    color: '#FFFFFF',
  },
  boxMainImage: {
    width:'9vw',
    height:'12vh',
    background:'#FFFFFF',
    borderRadius:'0px 0px 13.3271px 13.3271px',
  },
}))
