import React, { useState, useEffect, useRef } from 'react';
import {Link, Outlet } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CompassCalibrationIcon from '@mui/icons-material/CompassCalibration';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import Cookies from 'universal-cookie';
import { LOGOUT } from '../../constants/actionTypes';

const ListItems = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [cookie, removeCookie] = useCookies(["access_token"]);
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('access_token')));
  const [ open, setOpen ] = useState(false);

  const handleListItemClick = (event, index, path) => {
    setSelectedIndex(index);
    navigate(path);
  };

  const handleClickOpen = () => {
	   setOpen(true);
	}

	const handleClose = () => {
		  setOpen(false);
      console.log(window.location)
	}

  const handleLogout = () => {
    const cookies = new Cookies();
    const token = cookies.remove('access_token', { path: '/' });
    const id = cookies.remove('user_id', { path: '/' });
    setAuth(null);
    navigate('/');
}

  return (
  <div>
    <ListItemButton
      selected={selectedIndex === 0}
      style={{backgroundColor: selectedIndex === 0 ? '#283237' : ''}}
      onClick={(event) => handleListItemClick(event, 0, 'home')}
      sx={{color: '#FFFFFF'}}
    >
      <ListItemIcon>
        <DashboardIcon sx={{color:'#FFFFFF'}}/>
      </ListItemIcon>
      <ListItemText primary="Dashboard"/>
    </ListItemButton>
    <ListItemButton
      selected={selectedIndex === 1}
      style={{backgroundColor: selectedIndex === 1 ? '#283237' : ''}}
      onClick={(event) => handleListItemClick(event, 1, 'carList')}
      sx={{color: '#FFFFFF'}}
    >
      <ListItemIcon>
        <DirectionsCarIcon sx={{color:'#FFFFFF'}}/>
      </ListItemIcon>
      <ListItemText primary="Data Timbang"/>
    </ListItemButton>
    <ListItemButton
      selected={selectedIndex === 2}
      style={{backgroundColor: selectedIndex === 2 ? '#283237' : ''}}
      onClick={(event) => {handleListItemClick(event, 2, 'dataList')}}
      sx={{color: '#FFFFFF'}}
    >
      <ListItemIcon>
        <CompassCalibrationIcon sx={{color:'#FFFFFF'}}/>
      </ListItemIcon>
      <ListItemText primary="Data Kalibrasi"/>
    </ListItemButton>
    <ListItemButton
      selected={selectedIndex === 3}
      style={{backgroundColor: selectedIndex === 3 ? '#283237' : ''}}
      onClick={handleClickOpen}
      sx={{color: '#FFFFFF'}}
    >
      <ListItemIcon>
        <LogoutIcon sx={{color:'#FFFFFF'}}/>
      </ListItemIcon>
      <ListItemText primary="Logout"/>
    </ListItemButton>
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      sx={{ml:5}}
      hideBackdrop
      PaperProps={{
        elevation: 1,
        style:{
						backgroundColor:'#FFEFEF',
						padding:15
				}
      }}
    >
      <DialogTitle sx={{mb:2}}><Typography style={{fontFamily: 'Noto Sans', fontSize:'14px', fontWeight:'bold'}}>{"Apakah Ingin Keluar?"}</Typography></DialogTitle>
      <DialogActions style={{marginLeft:'auto', marginRight:'auto'}}>
        <Button variant="contained" color="error" style={{color:'#FFFFFF'}} onClick={handleClose}>Batal</Button>
        <Button variant="contained" style={{backgroundColor: '#2196F3', color:'#FFFFFF'}} onClick={handleLogout}>Setuju</Button>
      </DialogActions>
    </Dialog>
  </div>
);
}

export default ListItems;
