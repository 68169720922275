import axios from 'axios';
require('dotenv').config();

export const getDataByCode = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard?code=1:1:1:1:746f855b4509ab68`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDataTrigger = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard/trigger`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getAllPort = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard/portlist`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getCheckPort = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard/port`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getLastLogWeigh = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard/last_log`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDataKendaraan = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/list`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDataKendaraanByDate = (auth, startDate, endDate) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/list?start=${startDate}&end=${endDate}`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDataKalibrasi = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/calibration`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getDataKalibrasiByDate = (auth, startDate, endDate) =>
	axios.get(
		`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/vehicle/calibration?start=${startDate}&end=${endDate}`,
		{
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			withCredentials: false
		}
	);
export const getAllData = (auth) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/users/all`, {
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getReportDataKendaraanByDate = (auth, user_id, start, end) =>
	axios.get(
		`${process.env
			.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}&start=${start}&end=${end}&mode=weigh`,
		{
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			withCredentials: false
		}
	);
export const getReportDataKendaraan = (auth, user_id) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}&mode=weigh`, {
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
export const getReportDataKalibrasiByDate = (auth, user_id, start, end) =>
	axios.get(
		`${process.env
			.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}&start=${start}&end=${end}&mode=calibration`,
		{
			responseType: 'blob',
			headers: {
				'Content-Type': 'application/json',
				Accept: '*/*',
				token: auth
			},
			withCredentials: false
		}
	);
export const getReportDataKalibrasi = (auth, user_id) =>
	axios.get(`${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/download/report/?user_id=${user_id}&mode=calibration`, {
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: '*/*',
			token: auth
		},
		withCredentials: false
	});
