import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllData, getLastLogWeigh } from '../../actions/dashboard';
import Cookies from 'universal-cookie';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { ADD_MANUAL_SUCCESS } from '../../constants/actionTypes';
import Image from '../../Image/Group 20 (2) 1.png';
const cookies = new Cookies();
require('dotenv').config();

const mdTheme = createTheme();

export default function DataForm({ dataForm }) {
	const [ status, setStatus ] = useState('');
	const [ platNomor, setPlatNomor ] = useState('');
	const [ jenisMuatan, setJenisMuatan ] = useState('');
	const [ asalMuatan, setAsalMuatan ] = useState('');
	const [ beratMuatan, setBeratMuatan ] = useState('');
	const [ showdataForm, setShowDataForm ] = useState(false);
	const [ open, setOpen ] = useState(false);
	const data = useSelector((state) => state.data);
	const [ auth, setAuth ] = useState(decodeURI(cookies.get('access_token')));
	const [ userId, setUserId ] = useState(decodeURI(cookies.get('user_id')));
	const [ dataValid, setDataValid ] = useState(true);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getAllData(auth));
		console.log(data);
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (platNomor !== '' && jenisMuatan !== '' && asalMuatan !== '' && beratMuatan !== '') {
			const resp = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/dashboard/add`,
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
					'Content-Type': 'application/json',
					Accept: '*/*',
					token: auth
				},
				data: {
					user_id: userId,
					veh_id: platNomor,
					cat_id: jenisMuatan,
					city_id: asalMuatan,
					total_weight: beratMuatan,
					device_id: '0',
					log_status: 'false'
				}
			});

			const json = await resp;
			console.log(json);

			if (json.data) {
				dispatch({ type: ADD_MANUAL_SUCCESS, payload: json.data });
				dispatch(getLastLogWeigh(auth));
				setOpen(false);
				setDataValid(true);
			} else {
				console.log('error');
			}
		} else {
			setDataValid(false);
		}
	};

	const handleOpen = () => {
		setOpen(true);
		setPlatNomor('');
		setJenisMuatan('');
		setAsalMuatan('');
		setBeratMuatan('');
	};

	const handleClose = () => {
		setDataValid(true);
		setOpen(false);
	};
	const today = new Date();

	return (
		<ThemeProvider theme={mdTheme} className={classes.container}>
			{/* <Grid item xs={12} sx={{ml: -0.75}} >
        <Button
          type="submit"
          variant="contained"
          color="success"
          onClick={handleOpen}
          className={`${classes.textBtn} ${classes.btnInput}`}
          style={{
            width:'100%',
            backgroundColor: '#024A4F[500]',
            '&:hover': {backgroundColor: '#024A4F[700]',}
          }}
          // sx={{mt: 10}}
        >
          INPUT DATA MANUAL
        </Button>
      </Grid> */}
			<Grid container spacing={2} className={classes.container}>
				<Grid item xs={12} md={10} lg={10} sx={{ display: 'flex', flexDirection: 'row' }}>
					<Paper
						sx={{
							height: '146px',
							width: '100%',
							borderRadius: '5px'
						}}
					>
						<img
							src={Image}
							align="left"
							style={{ marginLeft: '25px', marginTop: '15px', marginRight: '25px' }}
							alt="image"
						/>
						<Box style={{ marginTop: '4vh' }}>
							<Typography
								style={{
									fontSize: '20px',
									fontFamily: 'Noto Sans',
									color: '#5D7078',
									marginRight: '25px'
								}}
							>
								{' '}
								<span style={{ fontWeight: 'bold' }}>Selamat datang </span> di Dashboard Server PT.
								Telen Orbit Prima Buhut Jaya Village, Sub-district of Central Kapuas, Kapuas, Central
								Kalimantan
							</Typography>
						</Box>
					</Paper>
				</Grid>

				{/*time*/}
				<Grid item xs={12} md={2} lg={2}>
					<Grid container spacing={'16px'}>
						<Grid item xs={12} lg={12}>
							<Paper
								sx={{
									// height: '146px',
									height: '65px',
									borderRadius: '5px',
									paddingTop: '15.5px' // (65 - 24) / 2
								}}
							>
								<Typography
									className={classes.date}
									style={{
										fontWeight: 'bold',
										fontSize: '24px',
										color: '#5B5B5B',
										textAlign: 'center'
									}}
								>
									{today.getHours() + ':' + today.getMinutes()} WIB
								</Typography>
							</Paper>
						</Grid>

						<Grid item xs={12} lg={12}>
							{/*button*/}
							{/* <DataForm /> */}
							<Button
								type="submit"
								variant="contained"
								onClick={handleOpen}
								// className={classes.textBtn}
								// className={classes.btnInput}
								// className={`${classes.textBtn} ${classes.btnInput}`}
								style={{
									width: '100%',
									backgroundColor: '#42A5F5[500]',
									'&:hover': { backgroundColor: '#42A5F5[700]' },
									height: '65px',
									borderRadius: '5px',
									// left:'6px',
									// right: '8px',
									// top: 'calc(50% - 32px/2)',
									fontFamily: 'Noto Sans',
									fontWeight: '500',
									fontSize: '14px',
									lineHeight: '16px',
									textAlign: 'center',
									letterSpacing: '1.25px',
									textTransform: 'uppercase'
								}}
								// sx={{mt: 10}}
							>
								INPUT DATA MANUAL
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.dataFormContainer}
				style={{ margin: 'auto' }}
				// className="modal-main"
			>
				<Container component="main" maxWidth="xs">
					<Paper className={classes.paper} style={{ borderRadius: '10px' }}>
						<Box className={classes.box}>
							<Typography
								className={classes.text}
								sx={{ padding: '3%', width: '100%' }}
								component="h3"
								variant="h8"
							>
								MASUKAN DATA MANUAL
							</Typography>
						</Box>
						<Container component="form" className={classes.form} noValidate>
							{!dataValid ? (
								<div>
									<Alert severity="error" sx={{ mt: 3 }}>
										Lengkapi Semua Data (*)
									</Alert>
								</div>
							) : null}
							<Grid container>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%', margin: '20px 0px 10px 0px' }}
										select
										required
										type="text"
										value={platNomor}
										name="platNomor"
										onChange={(e) => setPlatNomor(e.target.value)}
										id="platNomor"
										label="Nomor Lambung"
										autoComplete="platNomor"
									>
										{data.dataVin.map((data) => (
											<MenuItem key={data.veh_id} value={data.veh_id}>
												{data.veh_vin}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%', margin: '10px 0px 10px 0px' }}
										select
										required
										type="text"
										value={jenisMuatan}
										name="jenisMuatan"
										onChange={(e) => setJenisMuatan(e.target.value)}
										id="jenisMuatan"
										label="Jenis Muatan"
										autoComplete="jenisMuatan"
									>
										{data.dataCat.map((data) => (
											<MenuItem key={data.cat_id} value={data.cat_id}>
												{data.cat_code}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%', margin: '10px 0px 10px 0px' }}
										select
										required
										type="text"
										value={asalMuatan}
										name="asalMuatan"
										label="Asal Muatan"
										onChange={(e) => setAsalMuatan(e.target.value)}
										id="asalMuatan"
									>
										{data.dataCity.map((data) => (
											<MenuItem key={data.city_id} value={data.city_id}>
												{data.city_code}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} md={12} lg={12}>
									<TextField
										sx={{ width: '100%', margin: '10px 0px 10px 0px' }}
										required
										type="number"
										value={beratMuatan}
										name="beratMuatan"
										onChange={(e) => setBeratMuatan(e.target.value)}
										id="beratMuatan"
										label="Berat Muatan"
										autoComplete="beratMuatan"
									/>
								</Grid>
							</Grid>

							<Container style={{ padding: '20px' }}>
								{/* <Container > */}
								<Grid container style={{ display: 'flex', flexDirection: 'row' }}>
									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											variant="outlined"
											color="error"
											sx={{
												// mt:4,
												justifyContent: 'center',
												alignItems: 'center',
												width: '100%',
												height: '45px',
												// left:'150.25px',
												borderRadius: '4px'
											}}
											onClick={handleClose}
										>
											Batal
										</Button>
									</Grid>

									<Grid item xs={2} md={2} lg={2} />

									<Grid item xs={5} md={5} lg={5}>
										<Button
											type="submit"
											variant="contained"
											style={{
												backgroundColor: '#0C8D19',
												justifyContent: 'center',
												alignItems: 'center',
												// padding:'6px 6px 6px 8px',
												width: '100%',
												height: '45px',
												// left:'165.25px',
												borderRadius: '4px'
											}}
											// sx={{mt:4}}
											onClick={handleSubmit}
										>
											Simpan
										</Button>
									</Grid>
								</Grid>
							</Container>
						</Container>
					</Paper>
				</Container>
			</Modal>
		</ThemeProvider>
	);
}
