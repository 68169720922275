import { makeStyles } from  '@mui/styles';

export default makeStyles((theme) => ({
  text: {
    fontFamily: 'Noto Sans sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(69, 90, 100, 0.87)',
  },
  tableText: {
    fontFamily: 'Noto Sans sans-serif',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'rgba(69, 90, 100, 0.87)',
  },
  tableRow: {
    fontFamily: 'Noto Sans',
    fontSize: '14px',
  }
}));
