import React, { useState, useRef, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AUTH } from '../../constants/actionTypes';
import axios from 'axios';
import Image from '../../Image/image 1.png';
require('dotenv').config();

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
			sx={{
				fontFamily: 'Noto Sans',
				fontStyle: 'normal',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '19px',
				letterSpacing: '0.15px',
				color: '#FFFFFF',
				width: '100%',
				height: '19px',
				marginTop: '-7vh',
				texrAlign: 'center'
			}}
		>
			{'© 2022, All rights reserved. '}
		</Typography>
	);
}

const theme = createTheme();
const initialState = { email: '', password: '' };

export default function Login() {
	const [ formEmail, setFormEmail ] = useState('');
	const [ formPassword, setFormPassword ] = useState('');
	const [ cookies, setCookie ] = useCookies([ 'access_token' ]);
	const [ cookiez, setCookiez ] = useCookies([ 'user_id' ]);

	const [ accountValid, setAccountValid ] = useState(true);

	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const resp = await axios({
			method: 'post',
			url: `${process.env.REACT_APP_MY_ENVIRONMENT_VARIABLE}/api/login`,
			withCredentials: false,
			headers: {
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
				'Content-Type': 'application/json',
				Accept: '*/*'
			},
			data: {
				email: formEmail,
				password: formPassword
			}
		});

		const json = await resp;
		console.log(json);

		const dataUser = json.data.data;

		if (dataUser) {
			const data = dataUser.token;
			const id = dataUser.user_id;
			dispatch({ type: AUTH, data, id });
			navigate('/dashboard/home');
		} else {
			setAccountValid(false);
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }} className={classes.container}>
				<Grid item className={classes.boxMainImage} sx={{ ml: 12 }}>
					<Box component="span">
						<img
							src={Image}
							alt="image_top"
							style={{ width: '5vw', height: '3vh', margin: '3.5vh 2vw 3.5vh 2vw' }}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left' }}>
					<Grid item xs={12} sx={{ ml: 12 }}>
						<Typography
							component="h1"
							variant="h3"
							className={classes.header}
							style={{
								fontFamily: 'Noto Sans',
								fontStyle: 'normal',
								fontWeight: '700',
								fontSize: '36px'
							}}
						>
							DASHBOARD OPERATOR <br />{' '}
							<span className={classes.span1}> Jembatan Timbang Telen Orbit Prima </span>
						</Typography>
					</Grid>
					<Container component="main" maxWidth="xs">
						<CssBaseline />
						<Paper className={classes.paper} style={{ borderRadius: '20px' }}>
							<Box
								className={classes.paper2}
								sx={{ ml: -3.2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
								style={{ borderRadius: '20px 20px 0px 0px', borderBottom: '1px solid #C4C4C4' }}
							>
								<Typography
									component="h1"
									variant="h5"
									className={classes.login}
									sx={{ mt: -3 }}
									style={{
										fontFamily: 'Noto Sans',
										fontWeight: 'bold',
										fontSize: '24px'
									}}
								>
									Login
								</Typography>
							</Box>
							<Container component="form" noValidate sx={{ mt: 4 }}>
								{!accountValid ? (
									<div>
										<Alert severity="error" sx={{ mt: 3, mb: 2 }}>
											Email atau password salah
										</Alert>
									</div>
								) : null}
								<Grid container>
									<Grid item xs={12} md={12} lg={12}>
										<TextField
											sx={{ width: '100%' }}
											required
											fullWidth
											id="email"
											label="Email Address"
											name="email"
											autoComplete="email"
											autoFocus
											onChange={(e) => setFormEmail(e.target.value)}
										/>
									</Grid>
									<Grid item xs={12} md={12} lg={12}>
										<TextField
											sx={{ width: '100%' }}
											margin="normal"
											required
											fullWidth
											name="password"
											label="Password"
											type="password"
											id="password"
											autoComplete="current-password"
											onChange={(e) => setFormPassword(e.target.value)}
										/>
									</Grid>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										onClick={handleSubmit}
										sx={{ mt: 3, mb: 2, borderRadius: '4px' }}
										style={{ backgroundColor: '#024F17' }}
									>
										LOGIN
									</Button>
								</Grid>
							</Container>
						</Paper>
					</Container>
				</Grid>
			</Grid>
			<Copyright />
		</ThemeProvider>
	);
}
