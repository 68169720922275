import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import useStyles from './styles';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Table from './Table';

const mdTheme = createTheme();

function DashboardContent() {

  const [search, setSearch] = React.useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  const classes = useStyles();

  return (
    <ThemeProvider theme={mdTheme}>
        <Grid container spacing={3}>
          {/*Table*/}
          <Grid item xs={12}>
                <Table/>
            </Grid>
        </Grid>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <DashboardContent />;
}
